<template>
  <v-form>
    <form-subheader title="Monitoring items" class="py-4 mb-4" />
    <div v-for="(item, index) in monitoringItems" :key="item.id">
      <v-select
        v-model="items.monitoringItems[index].property"
        :items="properties"
        :loading="false"
        item-text="name"
        item-value="name"
        :label="item.defaultValue"
        @input="sendData"
      />
    </div>
    <form-subheader title="Geo items" />
    <v-select
      v-for="(item, index) in geoItems"
      :key="item.id"
      v-model="items.geoItems[index].id"
      :items="[...geozones, ...landmarks]"
      :loading="false"
      item-text="name"
      item-value="id"
      :label="`${item.defaultValue}`"
      @input="sendData"
    />
  </v-form>
</template>

<script>
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { onMounted, reactive, toRefs, watch } from '@vue/composition-api';

import schemaMonitoringItems from '@/modules/object-types/api/monitoring-item-list.graphql';
import schemas from '@/modules/objects/api/object-from-devices.graphql';

export default {
  name: 'ItemsTab',
  props: {
    data: { type: Object, default: () => ({}) },
    tab: { type: Number, default: null }
  },
  setup(props, { emit }) {
    const localState = reactive({
      items: {
        monitoringItems: [],
        geoItems: []
      }
    });
    const fetchMonitoringItems = useLazyQuery(
      schemaMonitoringItems.load,
      {
        parentSchemaId: ''
      },
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    fetchMonitoringItems.onResult(({ data }) => {
      if (localState.items.monitoringItems.length === 0) {
        data.schemata.forEach(item => {
          localState.items.monitoringItems.push({
            itemId: item.id,
            property: ''
          });
        });
      }
    });

    const fetchGeozones = useLazyQuery(
      schemas.fetchGeozones,
      {},
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    const fetchLandmarks = useLazyQuery(
      schemas.fetchLandmarks,
      {},
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    const fetchGeoItems = useLazyQuery(
      schemas.loadMonitoringGeoItems,
      {
        parentSchemaId: ''
      },
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    fetchGeoItems.onResult(({ data }) => {
      if (localState.items.geoItems.length === 0) {
        data.schemata.forEach(item => {
          localState.items.geoItems.push({
            itemId: item.id,
            id: ''
          });
        });
      }
    });

    const driverQuery = useLazyQuery(
      schemas.fetchDriver,
      {
        id: ''
      },
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    const monitoringItems = useResult(fetchMonitoringItems.result, [], data =>
      data.schemata.map(item =>
        item.schemaProperties.find(itemInner => itemInner.key === 'infoName')
      )
    );

    const geoItems = useResult(fetchGeoItems.result, [], data =>
      data.schemata.map(item =>
        item.schemaProperties.find(itemInner => itemInner.key === 'infoName')
      )
    );

    const properties = useResult(driverQuery.result, [], data =>
      data.schemata[0].schemaProperties.map(item => ({
        id: item.id,
        name: `${item.groupName}/${item.property}`
      }))
    );

    const landmarks = useResult(
      fetchLandmarks.result,
      [],
      data => data.objects
    );

    const geozones = useResult(fetchGeozones.result, [], data => data.objects);

    watch(
      () => props.tab,
      () => {
        initData();
      }
    );

    const initData = () => {
      const parentSchemaId = props.data['general'].type;
      const schemaDriverId = props.data['general'].objectType;

      if (parentSchemaId) {
        fetchMonitoringItems.variables.value.parentSchemaId = parentSchemaId;
        fetchMonitoringItems.load();
      }

      if (parentSchemaId) {
        fetchGeoItems.variables.value.parentSchemaId = parentSchemaId;
        fetchGeoItems.load();
      }

      if (schemaDriverId) {
        driverQuery.variables.value.id = schemaDriverId;
        driverQuery.load();
      }

      fetchGeozones.load();
      fetchLandmarks.load();
    };

    const sendData = () => {
      emit('update-form', {
        key: 'items',
        data: {
          geoItems: localState.items.geoItems,
          monitoringItems: localState.items.monitoringItems
        }
      });
    };

    onMounted(() => {
      initData();
    });

    return {
      ...toRefs(localState),
      monitoringItems,
      geoItems,
      properties,
      landmarks,
      geozones,
      sendData,
      loading:
        fetchMonitoringItems.loading ||
        driverQuery.loading ||
        fetchLandmarks.loading ||
        fetchGeozones.loading
    };
  }
};
</script>
